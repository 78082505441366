<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-24 mb-4">
        <strong>Reset Password</strong>
      </div>
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            size="large"
            placeholder="Email Address"
            v-decorator="['email', {rules: [{ required: true, message: 'Please input your Email!' }]}]"
          />
        </a-form-item>
        <a-button v-if="!loading" size="large" type="primary" class="text-center w-100" htmlType="submit">
          <strong>Reset my password</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/login" class="kit__utils__link font-size-16">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Go to Sign in
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CuiForgotPassword',
  data: function () {
    return {
      loading: false,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleSubmit(e) {
      this.loading = true
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('user/FORGOTPASSWORD', { payload: values }).catch(_err => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
